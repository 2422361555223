import React from 'react'

import Video from '../Suitcases/InfoSections/Video'

const InlineVideoPlayer = ({
  source,
  posterSrc,
  styles,
  noFade,
  style,
  noMobile,
  noDesktop,
}) => {
  return (
    <Video
      style={style}
      noFade={noFade}
      styles={styles}
      src={source}
      posterSrc={posterSrc}
      noMobile={noMobile}
      noDesktop={noDesktop}
    />
  )
}

export default InlineVideoPlayer
