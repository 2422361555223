import styled from '@emotion/styled'
import Image from 'gatsby-image/withIEPolyfill'
import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useContext } from 'react'

import Link from '../Link'

const HeaderVideoContainer = styled.div`
  position: relative;
  width: 50%;
  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  ${(props) =>
    props.fullLength ? 'width: 100%;height: calc(100vh - 110px);' : ''}
  @media (max-width: 1025px) {
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    ${(props) =>
      props.fullLength
        ? 'width: 100%;height: calc(100vh - 110px);'
        : 'height: 50vh;'}
  }
`

const HeaderCopyHeading = styled.div`
  /* width: 558px;
  height: 76px; */
  margin: 0 0 7px;
  object-fit: contain;
  font-family: 'essonnes-display';
  font-size: 106px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -1.46px;
  ${(props) => (props.color ? `color: rgb(var(--${props.color}));` : '')}

  @media (max-width : 1025px) {
    /* width: 392px;
    height: 54px; */
    font-size: 48px;
    line-height: 1.68;
    letter-spacing: -1.04px;
  }
  @media (max-width: 768px) {
    /* width: 261px;
    height: 55px; */
    margin: 0;
    font-size: 48px;
    line-height: 1.15;
    letter-spacing: -0.24px;
    text-align: center;
  }
`
const HeaderCopySubHeading = styled.div`
  font-family: Objectivity;
  font-size: 21px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: -0.21px;
  text-align: center;
  ${(props) => (props.color ? `color: rgb(var(--${props.color}));` : '')}

  @media (max-width : 1025px) {
    font-size: 18px;
    line-height: 1.56;
    letter-spacing: -0.16px;
  }
  @media (max-width: 768px) {
    opacity: 0.85;
    font-size: 16px;
    line-height: 1.48;
    letter-spacing: -0.12px;
    text-align: center !important;
  }
`

const SubHeading = styled.div`
  font-family: Objectivity;
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: -0.21px;
  text-align: center;
  ${(props) => (props.color ? `color: rgb(var(--${props.color}));` : '')}

  @media (max-width : 1025px) {
    font-size: 16px;
    line-height: 1.56;
    letter-spacing: -0.16px;
  }
  @media (max-width: 768px) {
    opacity: 0.85;
    font-size: 11px;
    line-height: 1.48;
    letter-spacing: -0.12px;
    text-align: center;
  }
`

const SectionContainerTop = styled.div`
  background-color: rgb(var(--${(props) => props.color}));
  position: relative;
  display: flex;
  align-items: stretch;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  @media (max-width: 768px) {
    ${(props) => (props.mobilePadding ? 'padding: 32px 0px;' : '')};
    flex-direction: ${(props) =>
      props.reverseMobile ? 'column-reverse' : 'column'};
    ${(props) => (!props.noMin ? 'height: calc(90vh - 48px);' : '')}
  }
  @media (min-width: 1024px) {
    ${(props) => (props.minHeight ? `min-height: ${props.minHeight};` : '')}
    ${(props) => (!props.noMin ? 'height: calc(80vh - 126px);' : '')}
    ${(props) => (props.negativeMargin ? 'height: calc(75vh - 126px);' : '')}
    ${(props) => (props.maxWidth ? 'max-width: 1200px; margin: 0 auto;' : '')}
  }
`

const CopyWidthContainer = styled.div`
  @media (min-width: 1025px) {
    max-width: 475px;
    ${({ center }) => (center === false ? '' : 'margin: 0 auto;')}
  }
  ${(props) =>
    props.elements === true
      ? `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    `
      : ''}
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 16px;
    margin-top: 42px;
    height: 100%;
  }
`

const TransparentButton = styled.button`
  font-family: 'swear-display';
  border-radius: 27px;
  border: solid 1px rgb(var(--${(props) => props.color}));
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 49px;
  letter-spacing: 0.45px;
  text-align: center;
  color: #fff;
  background: rgb(var(--${(props) => props.color}));
  height: 49px;
  padding: 0 16px;
  outline: none;
  cursor: pointer;
  @media (max-width: 1024px) {
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    font-size: 10px;
    height: 42px;
    line-height: 42px;
  }
  @media (min-width: 1025px) {
    ${(props) => (props.noDesktop ? 'display: none;' : '')}
  }
  ${(props) => (props.center ? 'margin: 0 auto;' : '')}

  transition: all 0.2s;

  &:hover {
    background-color: rgba(var(--${(props) => props.color}), 0.8);
    color: #fff;
    border-color: transparent;
  }
  &:active {
    background-color: rgba(var(--${(props) => props.color}), 0.7);
    color: #fff;
    border-color: transparent;
  }
`

const HeaderImage = styled(GatsbyImage)`
  width: 50%;
  height: 100%;
  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  @media (max-width: 1025px) {
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    ${(props) =>
      props.fullLength ? 'width: 100%;height: calc(100vh - 110px);' : ''}
  }
`
const HeaderImageOld = styled(Image)`
  width: 100%;
  height: 100%;
  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  @media (max-width: 1025px) {
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    ${(props) =>
      props.fullLength ? 'width: 100%;height: calc(100vh - 110px);' : ''}
  }
`
const SectionCopy = styled.div`
  position: absolute;
  width: ${(props) => (props.short ? '45%;' : '100%;')};
  left: 0;
  right: 0%;
  top: 10%;
  bottom: 0%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: rgb(var(--${(props) => props.color}));

  @media (min-width: 1026px) {
    ${({ trunk }) =>
      trunk && `align-items: flex-end; text-align: center; padding-right: 10%;`}
    ${({ school }) =>
      school && `align-items: flex-start; text-align: left; padding-left: 10%;`}
  }
  @media (max-width: 1025px) {
    justify-content: center;
    top: 0%;
    width: 100%;
    height: 35%;
    top: 0;

    text-align: left;
    z-index: 2;
    ${(props) => (props.left ? 'align-items: flex-start;' : '')};
    ${(props) => (props.lower ? 'padding: 0 32px;' : '')};
    ${(props) => (props.noTopPadding ? 'padding-top: 0;' : '')}
    ${(props) =>
      props.center
        ? 'text-align: center; align-items: center;justify-content: center;'
        : ''}
    ${({ trunk }) =>
      trunk &&
      `align-items: center;justify-content: center; text-align: center; `}
    ${(props) =>
      props.mobileAbsolute
        ? 'position: absolute; top: 22px; padding-top: 8px;'
        : ''}
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 320px) {
  }
`

const ButtonContainer = styled.div`
  margin-top: 3.2em;
  @media (max-width: 768px) {
    margin-top: 0.5em;
  }
`

const Desktop = styled.div`
  @media (max-width: 1050px) {
    display: none;
  }
`

const FullBanner = ({
  link,
  backgroundColor,
  reverse,
  headingCopy,
  subHeadingCopy,
  subCopy,
  CTACopy,
  image,
  left,
  title1,
  title2,
}) => {
  return (
    <Link noLink={link === undefined} to={link}>
      <SectionContainerTop
        color={backgroundColor}
        reverseMobile={true}
        noMin={true}
      >
        <HeaderImageOld
          loading="eager"
          objectFit="cover"
          objectPosition="bottom center"
          fluid={image}
        />
        <SectionCopy center={left === true ? false : true}>
          <CopyWidthContainer
            style={{ textAlign: left === true ? 'left' : 'center' }}
          >
            <HeaderCopyHeading large={true} color="magenta-pink">
              <div
                style={{
                  fontFamily: 'seriouslynostalgic_fnXCn',
                }}
              >
                {' '}
                {title1}{' '}
              </div>
              <div
                style={{
                  fontFamily: 'seriouslynostalgic_italCn',
                }}
              >
                {' '}
                {title2}{' '}
              </div>
            </HeaderCopyHeading>
          </CopyWidthContainer>
        </SectionCopy>
      </SectionContainerTop>
    </Link>
  )
}

export default FullBanner
