import styled from '@emotion/styled'
import Image from 'gatsby-image/withIEPolyfill'
import React, { useEffect, useState } from 'react'

import Link from './Link'

const ShopPanelsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 48px;
  @media (min-width: 1568px) {
    max-width: 1440px;
    margin: 0 auto;
    padding-bottom: 96px;
  }
  @media (max-width: 1567px) {
    margin: 0 64px;
  }
  @media (max-width: 1050px) {
    margin: 32px 16px;
  }
`

const ShopPanelContainer = styled.div`
  /* border: 1px solid black; */
  @media (min-width: 1568px) {
    width: calc(1440px / 3);
    ${(props) =>
      props.long &&
      `
      width: calc(1440px / 3 * 2);
    `}
  }
  @media (max-width: 1567px) {
    width: calc((100vw - 128px) / 3);
    ${(props) =>
      props.long &&
      `
      width: calc((100vw - 128px) / 3 * 2);
    `}
  }
  @media (max-width: 1050px) {
    padding: 16px 16px;
    width: calc((100vw - 32px) / 2);
    ${(props) =>
      props.long &&
      `
      width: calc((100vw - 32px) / 2 * 2);
      display: none;
    `}
  }
`

const ShopPanelImageContainer = styled.div`
  margin: 32px;
  ${(props) => !props.panel && 'padding: 32px;'}
  @media (min-width: 1568px) {
    width: calc((1440px / 3) - 64px);
    height: calc((1440px / 3) - 64px);
    ${(props) =>
      props.long &&
      `
      width:  calc((1440px / 3 * 2) - 64px);
    `}
  }
  @media (max-width: 1567px) {
    width: calc(((100vw - 128px) / 3) - 64px);
    height: calc(((100vw - 128px) / 3) - 64px);
    ${(props) =>
      props.long &&
      `
      width: calc(((100vw - 128px) / 3 * 2) - 64px);
    `}
  }
  @media (max-width: 1050px) {
    padding: 0px 0px;
    margin: 0;
    width: calc(((100vw - 96px) / 2));
    height: calc(((100vw - 96px) / 2));
    ${(props) =>
      props.long &&
      `
      width: calc(((100vw - 64px) / 2 * 2) );
    `}
  }
  background-color: rgb(var(--very-light-pink));
  margin-bottom: 16px;
`

const ShopPanelImage = styled(Image)`
  width: 100%;
  height: 100%;
`

const ShopPanelInfoContainer = styled.div`
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0 32px;
  color: black;
  @media (max-width: 1050px) {
    margin: 4px 0;
    height: 35px;
  }
`

const ShopPanelRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 30px;
  font-size: 18px;
  /* border: 1px solid black; */
  @media (max-width: 1050px) {
    font-size: 14px;
    line-height: 21px;
    justify-content: center;
  }
`

const ColorContainer = styled.span`
  & :last-child {
    margin-right: 0;
  }
  @media (max-width: 1050px) {
    display: none;
  }
`
const ColorContainerMobile = styled.div`
  display: none;
  @media (max-width: 1050px) {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 4px;
    height: 36px;
  }
`

export const ColorSelector = ({
  selectedColor,
  setSelectedColor,
  colors,
  addSix,
  small,
}) => {
  return colors.map((color, keyA) => {
    const key = addSix ? keyA + 6 : keyA
    return (
      <>
        <Circle
          size={small ? 24 : 32}
          key={key}
          style={{ marginLeft: key === 0 ? 0 : key === 6 ? 0 : 3 }}
          onClick={() => {
            return setSelectedColor(key)
          }}
          color={color.replace('545454', '0c0c0c').replace('4B4E5C', '324058')}
          black={color === '#E6E6E6'}
          selected={key === selectedColor}
        />
      </>
    )
  })
}

const Circle = styled.div`
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin: 0 7px;

  @media (max-width: 1050px) {
    width: 24px;
    height: 24px;
    margin: 0 4px;
  }

  ${(props) =>
    props.selected
      ? `border: solid 3px rgb(var(--duck-egg-blue));`
      : `border: solid 3px ${props.color};`}
  background-color: ${(props) => props.color};
  overflow: hidden;
  cursor: pointer;
  ${(props) =>
    props.color === '#101010'
      ? `
      &:before {
        content: " ";
        position: absolute;
        top: 4px;
        left: 4px;
        right: 4px;
        bottom: 4px;
        border-radius: 50%;
        background: white;
      }
    `
      : ''}
`

const ShopPanel = ({ image, name, to, price }) => {
  return (
    <ShopPanelContainer>
      <Link to={to}>
        <ShopPanelImageContainer>
          <ShopPanelImage
            objectFit="contain"
            fluid={image.childImageSharp.fluid}
          />
        </ShopPanelImageContainer>
      </Link>
      <ShopPanelInfoContainer>
        <ShopPanelRow style={{ height: 55, textAlign: 'center' }}>
          <span style={{ fontWeight: 500 }}> {name} </span>
        </ShopPanelRow>
        <ShopPanelRow>
          <span>{price}</span>
          <ColorContainer>
            <ColorSelector
              colors={['#C7296C']}
              small={true}
              setSelectedColor={() => {}}
              selectedColor={0}
            />
          </ColorContainer>
        </ShopPanelRow>
      </ShopPanelInfoContainer>
    </ShopPanelContainer>
  )
}

const ShopPage = ({ location, productData }) => {
  const [fade, setFade] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setFade(false)
    }, 100)
  }, [])

  let filteredProductData = productData

  return (
    <>
      <ShopPanelsContainer>
        <span id="shop-container" />
        {filteredProductData.map((val, key) => {
          return (
            <div
              className={
                fade === true
                  ? 'vis-hidden'
                  : `animate__animated animate__fadeInUp delay-c-${key}s`
              }
            >
              <ShopPanel
                name={val.name}
                image={val.image}
                to={val.to}
                price={val.price}
              />
            </div>
          )
        })}
      </ShopPanelsContainer>
    </>
  )
}

export default ShopPage
