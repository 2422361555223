import styled from '@emotion/styled'
import React from 'react'
import InViewMonitor from 'react-inview-monitor'

const Fade = ({ children, delay }) => {
  let delayClass = ''
  if (delay !== undefined) {
    delayClass = `delay-c-${delay}s`
  }
  return (
    <InViewMonitor
      classNameNotInView="vis-hidden"
      classNameInView={`animate__animated animate__fadeIn ${delayClass}`}
    >
      {children}
    </InViewMonitor>
  )
}

// Details
const DetailsContainer = styled.div`
  padding: 128px 25%;
  @media (max-width: 1024px) {
    padding: 64px 15%;
  }
  color: white;
  background-image: url('/03-magenta-graident.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CopyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 24px;
`

const DetailsSection = ({ copy }) => {
  return (
    <DetailsContainer>
      <Fade>
        <CopyContainer>{copy}</CopyContainer>
      </Fade>
    </DetailsContainer>
  )
}

export default DetailsSection
