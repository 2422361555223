import styled from '@emotion/styled'
import React, { useEffect, useRef } from 'react'
import InViewMonitor from 'react-inview-monitor'

const Fade = ({ children, noFade }) => {
  if (noFade) return children
  return (
    <InViewMonitor
      classNameNotInView="vis-hidden"
      classNameInView="animate__animated animate__fadeIn"
    >
      {children}
    </InViewMonitor>
  )
}

const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 2;
  height: 100%;
  overflow: hidden;

  ${({ noMobile }) =>
    noMobile &&
    `
    @media (min-width : 769px){
      display: none;
    }
  `}
  ${({ noDesktop }) =>
    noDesktop &&
    `
    @media (max-width : 768px){
      display: none;
    }
  `}
`

const StyledVideo = styled.video`
  max-width: 200%;
  width: 100%;
  object-fit: cover;
`
const StyledImage = styled.video`
  width: 100%;
`

const InfoSections = ({
  src,
  posterSrc,
  title,
  children,
  grey,
  styles,
  noFade,
  style,
  noMobile,
  noDesktop,
}) => {
  const video = useRef(null)
  const imageRef = useRef(null)
  useEffect(() => {
    imageRef.current.style.display = 'none'
    // if (navigator) {
    //   var isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
    //     navigator.userAgent &&
    //     navigator.userAgent.indexOf('CriOS') == -1 &&
    //     navigator.userAgent.indexOf('FxiOS') == -1;
    //   if(isSafari) {
    //     video.current.style.display = 'none'
    //   } else {
    //     imageRef.current.style.display = 'none'
    //   }
    // }
  }, [])
  return (
    <Fade noFade={noFade}>
      <VideoContainer
        noMobile={noMobile}
        noDesktop={noDesktop}
        style={{ ...styles, ...style }}
      >
        <StyledVideo
          // controls
          preload="yes"
          autoPlay
          muted
          loop
          controls={false}
          playsInline
          ref={video}
          poster={posterSrc}
        >
          <source src={src} type="video/mp4" />
          Sorry, your browser doesn't support embedded videos...
        </StyledVideo>
        <StyledImage id="img-tag" ref={imageRef} src={src} />
      </VideoContainer>
    </Fade>
  )
}

export default InfoSections
